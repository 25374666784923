import React from "react"
import Layout from "../components/layout.js"
import Button from "../components/button.js"
import Meta from "../components/meta.js"
import UncontrolledLottie from "../components/lottiething.js"
import * as styles from "./index.module.css"
import Link from "gatsby-link"

export default function Home() {
  return (
    <Layout style={{ height: `100%` }}>
      <Meta></Meta>{" "}
      <div className={styles.main}>
        <div className={styles.textArea}>
          <h1
            style={{
              fontWeight: `700`,
              fontSize: `4.5rem`,
              paddingTop: `2rem`,
            }}
          >
            <mark style={{ color: `black`, fontWeight: `700` }}>
              COЯE
              <span style={{ color: `#f26227` }}>17</span>
            </mark>
          </h1>
          <p
            style={{
              marginTop: `8px`,
              fontSize: `1.4rem`,
              fontWeight: `700`,
            }}
          >
            {" "}
            13th to 17th August
            {/*  Coming Soon*/}
          </p>
          <p style={{ marginTop: `25px`, fontSize: `1.1rem` }}>
            No more wait for{" "}
            <span style={{ fontWeight: `600` }}>Obsession</span>
            <sup>∞</sup>.
            <br />
            <br />
            Delhi Public School, Dwarka's annual tech symposium, Computer Obsessed Radical Enthusiasts, is back!
            {/*  Delhi Public School, Dwarka's annual tech symposium, Computer Obsessed Radical Enthusiasts will be back sooner than you expect!*/}
            <br /> <br />
            From gaming to design, hacking to music production - test your obsession at one of the biggest events of the year. 
            <br /><br />
            {/*<span style={{ fontWeight: `700` }}>Register for COЯE 17 now!</span>*/}
            {/*<span style={{ fontWeight: `700` }}>Check out COЯE 16 results!</span>*/}
          </p>
          <div
            style={{
              marginTop: `25px`,
              display: `flex`,
              justifyContent: `baseline`,
            }}
          >
            <Link to="https://chat.whatsapp.com/ErUX3UsBXff0wgIS2I9wiS"style={{}}>
              <Button style={{}} className={styles.chutiya}>WhatsApp</Button>
            </Link>
          </div>
        </div>
        <div style={{ marginTop: `` }}>
          
          <div className={styles.circle}>
              <div className={styles.rotate}></div>
              <span style={{marginLeft: "-5%"}}><i>17</i></span>
          </div>
              
           {/*<UncontrolledLottie className={styles.anim}></UncontrolledLottie>*/} 
          {/*<video
            width="400"
            height="400"
            className="videoTag"
            autoPlay
            loop
            muted
          >
            <source src={video} type="video/mp4" />
          </video>*/}
        </div>
      </div>
    </Layout>
  )
}
